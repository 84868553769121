import type { FC } from 'react';
import React, { useContext } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';

import {
	SSRMouseEventWrapper,
	SSR_NAV_CUSTOM_HOMEPAGE_BUTTON_METRIC,
} from '@confluence/browser-metrics';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { RoutesContext } from '@confluence/route-manager';
import { SPAViewContext } from '@confluence/spa-view-context';

import { HomeIconComponent } from '../GlobalNavigationIcons';

import type { GlobalItemProps } from './globalItemProps';

const i18n = defineMessages({
	CustomHomeLink: {
		id: 'side-navigation.custom.home.link',
		description:
			'link to the a special custom Home page that was set by a site admin. In addition to "your-work" it adds an additional item to the nav that links to a specific space overview',
		defaultMessage: 'Custom Home Page',
	},
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ '../analyticsCallbacks'))
			.fireCustomHomepageClickedAnalytics,
);

export const CustomHomeItem: FC<GlobalItemProps> = () => {
	//takes globalItemProps but is not customizable so doesn't use them
	const { location, match } = useContext(RoutesContext);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, match?.name);
	const { homepageUri, homepageTitle } = useContext(SPAViewContext);

	const isSelected =
		!!location &&
		location.pathname === `${CONTEXT_PATH}${homepageUri}` &&
		location.search.includes('mode=global');

	return (
		<SSRMouseEventWrapper metricName={SSR_NAV_CUSTOM_HOMEPAGE_BUTTON_METRIC}>
			<MenuLinkItem
				href={`${CONTEXT_PATH}${homepageUri}?mode=global`}
				elemBefore={HomeIconComponent}
				isSelected={isSelected}
				onClick={fireClickAnalytics}
			>
				{homepageTitle || <FormattedMessage {...i18n.CustomHomeLink} />}
			</MenuLinkItem>
		</SSRMouseEventWrapper>
	);
};
